body {
  font-family: "Roboto Slab", Arial, Helvetica, sans-serif;
}

footer {
  background-color: #2e3036;
  color: white;
  font-family: "Roboto Slab", serif;
  font-size: 13px;
  font-weight: 400;
}

svg {
  fill: rgba(255, 255, 255, 0.5);
  max-width: 80%;
}

.payment-icons > li {
  display: inline-block;
  margin: 42px 5px;
  width: 50px;
}

a {
  color: inherit;
  transition: color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: white;
}

.btn {
  padding: 1.0625em;
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3125;
  color: #fff;
  background-color: #a1c25a;
  border-color: #a1c25a;
  border-radius: 4px;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #b6bac2;
  border-color: #b6bac2;
}

.error {
  display: inherit;
  font-size: 80%;
  color: #dc3545;
}

.invalid > .invalid-feedback,
.invalid > .input-group > .invalid-feedback {
  display: block;
}

.invalid > input,
.invalid > .input-group > input {
  border-color: #dc3545;
}
